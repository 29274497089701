<template>
  <div id="profile-tournaments" class="profile-tournaments">
    <headline v-if="showSubtitle" class="centered spaced">
      <h3 slot="title">
        {{
          $t(
            `tournaments.variant_${
              isCurrentUserProfile ? 'my' : 'player'
            }`,
          )
        }}
      </h3>
    </headline>

    <loader
      v-if="loadingState.page"
      class="loading-container"
      :class="{ bg: tournamentsIds.length }"
      :size="64"
    />

    <tournaments-filter
      v-if="userHasTournaments && !hideFilter"
      v-model="filter"
    />

    <template v-if="tournamentsIds.length">
      <TournamentsTable
        :tournaments-ids="tournamentsIds"
        :show-pagination="showPagination"
        :pagination-params="paginationParams"
        @setPage="setPage"
      />
    </template>
    <div
      v-else-if="!loadingState.page"
      class="tournaments-placeholder"
    >
      <div class="placeholder-text">
        {{
          isCurrentUserProfile
            ? placeholderText.current
            : placeholderText.other
        }}
      </div>
    </div>
  </div>
</template>

<script>
import TournamentsTable from './TournamentsTable.vue';
import TournamentsFilter from './TournamentsFilter.vue';

export default {
  name: 'ProfileTournaments',
  components: {
    TournamentsFilter,
    TournamentsTable,
  },
  props: {
    hash: {
      type: String,
      required: true,
    },
    hideFilter: {
      type: Boolean,
      default: false,
    },
    showSubtitle: {
      type: Boolean,
      default: false,
    },
    gameId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      tournamentsIds: [],
      userHasTournaments: null,

      // Filters
      filter: {
        type: 'all',
        gameId: null,
      },

      // Pagination
      currentPage: 1,
      perPage: 12,
      total: 0,
      perPageSizes: [12, 36, 72],

      // Sort
      sortFiled: 'date',
      sortOrder: 'desc',
    };
  },
  computed: {
    ...mapGetters('users', ['getUserProfile']),
    ...mapGetters('profile', ['getCurrentProfileHash']),

    isCurrentUserProfile() {
      return this.getCurrentProfileHash === this.hash;
    },

    paginationParams() {
      return {
        page: this.currentPage,
        perPage: this.perPage,
        total: this.total,
        perPageSizes: this.perPageSizes,
      };
    },

    showPagination() {
      return this.total > _.head(this.perPageSizes);
    },

    currentTab() {
      return this.tabs[this.currentTabIndex];
    },

    profile() {
      return this.getUserProfile(this.profileHash);
    },

    placeholderText() {
      const filterMessage = {
        current: this.$t('profile.no-tournaments_filters_current'),
        other: this.$t('profile.no-tournaments_filters_other'),
      };

      if (!this.filter.gameId && this.filter.type === 'all') {
        return {
          current: this.$t('profile.no-tournaments_default_current'),
          other: this.$t('profile.no-tournaments_default_other'),
        };
      }

      return filterMessage;
    },
  },
  watch: {
    paginationParams: {
      handler() {
        this.scrollToElement({
          element: _.get(this.$refs, 'tournaments-list.$el'),
          offset: -200,
        });
      },
    },
    filter: {
      handler(value) {
        this.sortOrder = ['started', 'future'].includes(value.type)
          ? 'asc'
          : 'desc';
        this.reloadTournaments(1);
      },
      deep: true,
    },
    gameId: {
      handler(value) {
        this.filter.gameId = value;
      },
      immediate: true,
    },
  },
  created() {
    this.reloadTournaments();
  },
  methods: {
    ...mapActions('tournaments', ['fetchProfileTournaments']),

    setPage({ page, perPage }) {
      this.perPage = perPage;
      this.reloadTournaments(page);
    },

    onSortToggled() {
      this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
      this.reloadTournaments(1);
    },

    reloadTournaments(page = null) {
      if (page !== null) {
        this.currentPage = page;
      }

      this.loadingState.page = true;
      this.loadTournaments();
    },

    loadTournaments: function () {
      this.fetchProfileTournaments({
        hash: this.hash,
        type: this.filter.type,
        gameId: this.filter.gameId,
        page: this.currentPage,
        limit: this.perPage,
        sort: {
          field: this.sortFiled,
          order: this.sortOrder,
        },
      })
        .then((result) => {
          this.tournamentsIds = result.tournaments;
          this.total = result.pagination.total;

          if (this.userHasTournaments === null) {
            this.userHasTournaments = !!this.total;
          }
        })
        .finally(() => {
          this.loadingState.page = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.profile-tournaments {
  min-height: 200px;
  position: relative;

  @include max-tablet() {
    margin-left: -12px;
    margin-right: -12px;
  }

  .loading-container {
    position: absolute;
    inset: 0;
    z-index: 4;
    display: flex;
    justify-content: center;
    padding-top: 100px;

    &.bg {
      background-color: rgba($dark, 0.8);
    }
  }

  .tournaments-list {
    position: relative;

    & + .pagination {
      margin-top: 20px;
    }
  }

  .tournaments-placeholder {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: $dark-background;
    border-radius: 3px;
    box-shadow: $default-box-shadow;
    padding-top: 30px;
    padding-bottom: 30px;
    @include max-tablet() {
      padding: 30px 12px;
    }

    .placeholder-text {
      text-align: center;
      font-size: 16px;
    }

    .button {
      margin-top: 12px;
    }
  }

  .tournaments-filter {
    margin-bottom: 20px;
  }
}

.headline {
  margin-top: 30px;
  margin-bottom: 18px;
  @include max-tablet() {
    margin-top: 6px;
    padding-left: 12px;
  }
}

::v-deep .filters-list {
  z-index: 3;
}
</style>
