<template>
  <div v-if="tournament.isExist" class="tournament-status">
    <template v-if="!tournament.isHub">
      <div
        v-if="tournament.stateInfo.text"
        class="state-text"
        :class="tournament.stateInfo.type"
      >
        {{ tournament.stateInfo.text }}
      </div>

      <pvp-btn
        v-if="tournament.stateInfo.canConfirm"
        :is-loading="isConfirmLoading"
        variant="warning"
        @click="confirmRegistration"
      >
        {{ $t('tournaments.confirmParticipation') }}
      </pvp-btn>
      <pvp-btn
        v-else-if="tournament.stateInfo.canSearchMatch"
        :to="tournament.link"
      >
        {{ $t('matches.toSearch') }}
      </pvp-btn>
      <pvp-btn
        v-else-if="tournament.stateInfo.canRegister"
        :variant="btnColor"
        :is-loading="isRegisteringToTournament(id)"
        @click="startRegistration(id)"
      >
        {{ $t('global.register') }}
      </pvp-btn>

      <div
        class="status-text"
        :class="[
          tournament.statusCode,
          { confirm: tournament.needCheck },
        ]"
      >
        <div v-if="tournament.statusInfo.text" class="text">
          {{ tournament.statusInfo.text }}
        </div>
        <time-left
          v-if="tournament.statusInfo.to"
          :to="tournament.statusInfo.to"
          is-server-time
        />
      </div>
    </template>
    <div v-else class="tournament-status__hub">
      {{ hubStatuses(tournament.statusCode) }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'TournamentStatus',
  props: {
    id: {
      type: Number,
      required: true,
    },
    btnColor: {
      type: String,
      default: 'primary',
    },
  },

  data: () => ({
    isConfirmLoading: false,
  }),

  computed: {
    ...mapGetters('tournamentRegistration', [
      'isRegisteringToTournament',
    ]),
    ...mapGetters('tournaments', ['getTournamentTable']),

    tournament() {
      return this.getTournamentTable(this.id);
    },
  },

  methods: {
    ...mapActions('tournamentRegistration', ['startRegistration']),
    ...mapActions('profile', ['fetchProfileTournamentsStatus']),

    hubStatuses(status) {
      const statuses = {
        cancelled: this.$t('tournaments.status_canceled'),
        published: this.$t('tournaments.state_announced'),
        finished: this.$t('tournaments.status_completed'),
        created: this.$t('tournaments.hub_not_announced'),
        executing: this.tournament?.isHubMatchMakingEnabled
          ? this.$t('tournaments.hub_is_active')
          : this.$t('tournaments.hub_is_not_active'),
      };
      return statuses[status];
    },

    confirmRegistration() {
      this.isConfirmLoading = true;

      return api
        .post(`/tournament/${this.id}/confirm`, {
          teamHash: this.tournament?.userInfo?.team?.hash,
        })
        .then(this.fetchProfileTournamentsStatus)
        .finally(() => {
          this.isConfirmLoading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.tournament-status {
  text-align: center;
}

.state-text {
  font-weight: bold;

  &:before {
    content: '';
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    margin-right: 8px;
  }

  &.danger {
    color: $orangey-red;

    &:before {
      background-color: $orangey-red;
    }
  }

  &.warning {
    color: $gold;

    &:before {
      background-color: $gold;
    }
  }

  &.success {
    color: $dark-pastel-green;

    &:before {
      background-color: $dark-pastel-green;
    }
  }

  & + .button,
  & + .status-text {
    margin-top: 8px;
  }
}

.button + .status-text {
  margin-top: 8px;
}

.status-text {
  color: rgba(white, 0.5);
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .time-left {
    margin-top: 4px;
    display: block;
  }

  &.regopen.confirm {
    color: $gold;
  }

  &.published {
    color: $azure;
  }

  @include max-laptop() {
    display: flex;

    .time-left {
      margin-left: 4px;
      margin-top: 0;
    }
  }
}

.tournament-status__hub {
  color: rgba(255, 255, 255, 0.5);
}
</style>
