<template>
  <div class="table-column">
    <BaseLink
      :to="tournament.link"
      :class="{ favorite: tournament.isInRecommendedList }"
      class="tournament"
      @click-native="tournamentClickHandler()"
    >
      <span class="tournament-name">
        <icon class="star" name="star" inline />
        {{ tournament.name }}
      </span>
      <game-tag :code="tournament.gameCode" :full-name="false" />
    </BaseLink>
  </div>
</template>

<script>
import { pushEvents } from '@utils/metricEvents.js';
import Icon from '@components/v2/utils/Icon.vue';
import BaseLink from '@components/BaseComponents/BaseLink.vue';

export default {
  name: 'CellTournament',
  components: {
    Icon,
    BaseLink,
  },
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
  },
  computed: {
    ...mapGetters('tournaments', ['getTournamentTable']),
    ...mapGetters('application', ['getGameByCode']),

    tournament() {
      return this.getTournamentTable(this.id);
    },
  },

  methods: {
    tournamentClickHandler() {
      // ======================================================
      // METRICS Турниры. Переход на страницу турнира на турнирах
      const tmrParams = {
        category: 'pvp',
        action: 'tourn_tournament_click',
        label: this.getGameByCode(this.tournament.gameCode).name,
        url: window.location.href,
      };
      pushEvents('pvp_tourn_tournament_click', tmrParams);
      // ======================================================
    },
  },
};
</script>

<style lang="scss" scoped>
.tournament {
  color: #fff;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  position: relative;
  @include max-laptop() {
    width: 100%;
  }

  &:hover {
    text-decoration: none;

    .game-tag {
      color: white;
    }
  }

  &.favorite {
    .star {
      display: block;
    }
  }
}

.star {
  display: none;
  color: $azure;
  flex-shrink: 0;
}

.game-tag {
  display: flex;
  font-weight: 400;
}

.tournament-name {
  display: flex;
  gap: 6px;
  margin-bottom: 6px;
}
</style>
