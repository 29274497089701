<template>
  <li class="tournament-card">
    <h3 class="tournament-card__title">
      <slot name="title" />
    </h3>

    <div class="tournament-card__config">
      <slot name="data" />
    </div>

    <slot name="registration" />
  </li>
</template>

<script>
export default {
  name: 'TournamentMobileCard',
};
</script>

<style lang="scss" scoped>
.tournament-card {
  padding: 16px;
  color: #f5f5f7;
  list-style-type: none;

  &:nth-child(odd) {
    background: #100f0f;
  }
  &:nth-child(even) {
    background: #161516;
  }
  &:first-child {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }
  &:last-child {
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
  }
}

.tournament-card__title {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.3px;
  margin-bottom: 20px;
}

.tournament-card__config {
  display: grid;
  grid-template-columns: 5fr 7fr;
  gap: 16px;
  margin-bottom: 20px;
}

.tournament-card__data-title {
  font-size: 12px;
  line-height: 16px;
  color: #979797;
  margin-bottom: 4px;
}
.tournament-card__data-value {
  font-size: 14px;
  line-height: 18px;
  color: #f5f5f7;
}
</style>
