<template>
  <div class="tournaments-filter">
    <template v-if="appIsMobile && !mobileFilterVisible">
      <pvp-btn
        variant="secondary"
        block
        @click="mobileFilterVisible = true"
      >
        {{ $t('filter.open') }}
      </pvp-btn>
    </template>
    <template v-else>
      <div class="filters-list">
        <div class="list-item type">
          <div class="filter-label">{{ $t('global.status') }}:</div>
          <div class="filter-control">
            <div
              v-for="status in statuses"
              :key="status.value"
              class="type-check"
            >
              <BaseRadio v-model="type" :value="status.value"
                >{{ status.title }}
              </BaseRadio>
            </div>
          </div>
        </div>
        <div class="list-item game">
          <div class="filter-label">{{ $t('games.single') }}:</div>
          <div class="filter-control">
            <GameSelect v-model="gameId" />
          </div>
        </div>
      </div>
      <div v-if="resetButtonVisible" class="filter-controls">
        <pvp-btn
          icon-left="cross"
          variant="secondary"
          @click="resetFilter"
          >{{ $t('filter.skip') }}
        </pvp-btn>
      </div>
    </template>
  </div>
</template>

<script>
import GameSelect from '@components/BaseComponents/Select/GameSelect.vue';
import BaseRadio from '@components/BaseComponents/Form/Radio.vue';

const defaultFilter = {
  type: 'all',
  gameId: null,
};

export default {
  name: 'TournamentsFilter',
  components: {
    GameSelect,
    BaseRadio,
  },
  props: {
    value: {
      type: Object,
      default: () => defaultFilter,
    },
  },
  data() {
    return {
      type: defaultFilter.type,
      gameId: defaultFilter.gameId,

      mobileFilterVisible: false,
    };
  },
  computed: {
    ...mapGetters('application', ['appIsMobile']),

    statuses() {
      return [
        {
          title: this.$t('tournaments.tabs_all'),
          value: 'all',
        },
        {
          title: this.$t('tournaments.tabs_upcoming'),
          value: 'future',
        },
        {
          title: this.$t('tournaments.tabs_current'),
          value: 'started',
        },
        {
          title: this.$t('tournaments.tabs_completed'),
          value: 'finished',
        },
      ];
    },
    resetButtonVisible() {
      return (
        this.type !== defaultFilter.type ||
        this.gameId !== defaultFilter.gameId
      );
    },
  },
  watch: {
    type: 'emitChange',
    gameId: 'emitChange',
  },
  methods: {
    resetFilter() {
      this.type = defaultFilter.type;
      this.gameId = defaultFilter.gameId;

      this.mobileFilterVisible = false;
    },

    emitChange() {
      this.$emit('input', {
        type: this.type,
        gameId: this.gameId,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.tournaments-filter {
  background-color: $dark-background;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 12px 12px 12px 22px;

  @include max-tablet() {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 12px;
  }

  .filters-list {
    display: flex;
    align-items: center;

    @include max-tablet() {
      width: 100%;
    }

    .list-item {
      display: flex;
      align-items: center;

      .filter-label {
        font-size: 14px;
        color: rgba(white, 0.7);

        @include max-tablet() {
          min-width: 50px;
        }
      }

      .filter-label + .filter-control {
        margin-left: 20px;
      }

      &.type {
        @include max-tablet() {
          flex-direction: column;
        }
        .filter-label {
          @include max-tablet() {
            align-self: flex-start;
            margin-top: 2px;
          }
        }

        .type-check + .type-check {
          margin-left: 22px;

          @include max-tablet() {
            margin-left: 0;
            margin-top: 4px;
          }
        }

        .filter-control {
          display: flex;
          align-items: center;
          margin-top: 4px;

          @include max-tablet() {
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            margin-left: 0;
          }
        }
      }

      &.game {
        min-width: 200px;

        @include max-tablet() {
          width: 100%;
        }

        .filter-control {
          flex: 1 0 100%;

          @include max-tablet() {
            margin-left: 0;
            width: 100%;
          }
        }

        @include max-tablet() {
          flex-direction: column;
          align-items: flex-start;
        }
      }
    }

    .list-item + .list-item {
      margin-left: 20px;
      padding-left: 20px;
      border-left: 1px solid rgba(white, 0.1);
    }

    @include max-desktop() {
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;

      .list-item + .list-item {
        margin-left: 0;
        padding-left: 0;
        border-left: none;
        margin-top: 10px;
      }
    }
  }

  .filter-controls {
    margin-left: auto;

    @include max-laptop() {
      align-self: flex-end;
    }

    @include max-tablet() {
      margin-left: 0;
      margin-top: 20px;
      align-self: center;
    }
  }
}
</style>
