<template>
  <div class="tournaments-table">
    <div class="main__table">
      <template v-if="tournaments.length">
        <Table>
          <template #thead>
            <TableRow>
              <TableData
                v-for="(header, idx) in tableHeaders"
                :key="idx"
                :text-align="idx == 0 ? 'left' : 'center'"
                heading
              >
                {{ header }}
              </TableData>
            </TableRow>
          </template>
          <template #tbody>
            <TableRow
              v-for="tournament in tournaments"
              :key="tournament.id"
            >
              <TableData text-align="left" white-space="normal">
                <cell-tournament :id="tournament.id" />
              </TableData>
              <TableData>
                <cell-simple>
                  <pvp-tooltip>
                    {{ $dt(tournament.tsStartRun, 'dd_MM_HH_mm') }}
                    <template slot="tooltip">{{
                      $t('tournaments.tooltips_start')
                    }}</template>
                  </pvp-tooltip>
                </cell-simple>
              </TableData>
              <TableData>
                <cell-simple class="centered">
                  <pvp-tooltip class="duration">
                    <template slot="tooltip">{{
                      $t('tournaments.tooltips_duration')
                    }}</template>
                    <div
                      v-for="(text, key) in tournament.duration"
                      :key="key"
                    >
                      {{ text }}
                    </div>
                  </pvp-tooltip>
                </cell-simple>
              </TableData>
              <TableData>
                <cell-simple class="centered mobile">
                  <pvp-tooltip>
                    <template slot="tooltip">
                      {{
                        $t('tournaments.tooltips_format_text', {
                          counts: $tc(
                            'tournaments.tooltips_format_counts',
                            tournament.numTeamMembers,
                          ),
                        })
                      }}
                    </template>
                    {{ tournament.format }}
                  </pvp-tooltip>
                </cell-simple>
              </TableData>
              <TableData>
                <cell-simple>
                  <pvp-tooltip class="tournament-participants">
                    <template slot="tooltip">{{
                      $t('tournaments.tooltips_participants')
                    }}</template>
                    {{ tournament.participantsCountStatistic }}
                  </pvp-tooltip>
                </cell-simple>
              </TableData>
              <TableData>
                <TournamentConditions
                  :is-trusted="tournament.isTrusted"
                  :is-anticheat="tournament.hasAntiCheat"
                  :trust-tooltip-text="
                    $t(
                      `organizations.trusted_${
                        tournament.isTrusted ? 'enabled' : 'disabled'
                      }`,
                    )
                  "
                  :anticheat-tooltip-text="
                    $t(
                      `tournaments.tooltips_anticheat_${
                        tournament.hasAntiCheat
                          ? 'enabled'
                          : 'disabled'
                      }`,
                    )
                  "
                />
              </TableData>
              <TableData>
                <cell-simple class="custom">
                  <template v-if="tournament.hasPrizes">
                    <pvp-tooltip
                      v-for="(prize, key) in tournament.prizes"
                      :key="key"
                      class="prize"
                    >
                      <div class="value">{{ prize.count }}</div>
                      <icon :name="prize.icon" inline />
                      <template slot="tooltip">{{
                        prize.tooltip
                      }}</template>
                    </pvp-tooltip>
                  </template>
                </cell-simple>
              </TableData>
              <TableData>
                <cell-simple class="mobile custom">
                  <slot name="status" :tournament="tournament">
                    <tournament-status
                      :id="tournament.id"
                      btn-color="boost"
                    />
                    <div
                      v-if="tournament.participationResult"
                      class="participation-result"
                    >
                      {{ tournament.participationResult.text }}
                    </div>
                  </slot>
                </cell-simple>
              </TableData>
            </TableRow>
          </template>
        </Table>
        <pagination
          v-if="showPagination"
          class="pagination"
          :params="paginationParams"
          :disabled="loadingState.tournaments"
          @onchange="setPage"
        />
      </template>
      <div v-else class="empty">
        <icon class="empty-icon" name="vkplay" />
        <div class="empty-text">
          {{ $t('tournaments.placeholders_filters') }}
        </div>
      </div>
    </div>
    <div class="main__tournaments-card">
      <ul v-if="tournaments.length">
        <TournamentMobileCard
          v-for="tournament in tournaments"
          :key="tournament.id"
        >
          <template #title>
            <cell-tournament :id="tournament.id" />
          </template>
          <template #data>
            <div>
              <h3 class="cell-title">{{ $t('global.start') }}</h3>
              <cell-simple>
                <pvp-tooltip>
                  {{ $dt(tournament.tsStartRun, 'dd_MM_HH_mm') }}
                  <template slot="tooltip">{{
                    $t('tournaments.tooltips_start')
                  }}</template>
                </pvp-tooltip>
              </cell-simple>
            </div>
            <div>
              <h3 class="cell-title">
                {{ $t('global.durationShort') }}
              </h3>
              <cell-simple class="centered">
                <pvp-tooltip class="duration">
                  <template slot="tooltip">{{
                    $t('tournaments.tooltips_duration')
                  }}</template>
                  <div
                    v-for="(text, key) in tournament.duration"
                    :key="key"
                  >
                    {{ text }}
                  </div>
                </pvp-tooltip>
              </cell-simple>
            </div>

            <div>
              <h3 class="cell-title">
                {{ $t('tournaments.system') }}
              </h3>
              <cell-simple class="centered mobile">
                <pvp-tooltip>
                  <template slot="tooltip">
                    {{
                      $t('tournaments.tooltips_format_text', {
                        counts: $tc(
                          'tournaments.tooltips_format_counts',
                          tournament.numTeamMembers,
                        ),
                      })
                    }}
                  </template>
                  {{ tournament.format }}
                </pvp-tooltip>
              </cell-simple>
            </div>

            <div>
              <h3 class="cell-title">{{ $t('participants.few') }}</h3>
              <cell-simple>
                <pvp-tooltip class="tournament-participants">
                  <template slot="tooltip">{{
                    $t('tournaments.tooltips_participants')
                  }}</template>
                  {{ tournament.participantsCountStatistic }}
                </pvp-tooltip>
              </cell-simple>
            </div>

            <div>
              <h3 class="cell-title">
                {{ $t('tournaments.prizePool') }}
              </h3>
              <cell-simple>
                <template v-if="tournament.hasPrizes">
                  <pvp-tooltip
                    v-for="(prize, key) in tournament.prizes"
                    :key="key"
                    class="prize"
                  >
                    <div class="prize__value">{{ prize.count }}</div>
                    <icon :name="prize.icon" />
                    <template slot="tooltip">{{
                      prize.tooltip
                    }}</template>
                  </pvp-tooltip>
                </template>
                <p v-else>{{ $t('boost.stay_out') }}</p>
              </cell-simple>
            </div>
          </template>

          <template #registration>
            <cell-simple class="mobile custom">
              <slot name="status" :tournament="tournament">
                <tournament-status
                  :id="tournament.id"
                  btn-color="boost"
                />
                <div
                  v-if="tournament.participationResult"
                  class="participation-result"
                >
                  {{ tournament.participationResult.text }}
                </div>
              </slot>
            </cell-simple>
          </template>
        </TournamentMobileCard>
        <pagination
          v-if="showPagination"
          class="pagination"
          :params="paginationParams"
          :disabled="loadingState.tournaments"
          @onchange="setPage"
        />
      </ul>
      <div v-else class="empty">
        <icon class="empty-icon" name="vkplay" />
        <div class="empty-text">
          {{ $t('tournaments.placeholders_filters') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Table from '@src/components/v2/ui/Table.vue';
import TableRow from '@src/components/v2/ui/TableRow.vue';
import TableData from '@src/components/v2/ui/TableData.vue';
import TournamentMobileCard from '@src/components/v2/TournamentMobileCard.vue';
import TournamentConditions from '@src/components/v2/TournamentConditions.vue';
import Pagination from '@src/components/v2/Pagination.vue';
import CellTournament from '@components/TableCells/CellTournament.vue';
import CellSimple from '@components/TableCells/CellSimple.vue';
import TournamentStatus from '@components/TournamentComponents/Lists/TournamentStatus.vue';
import Icon from '@components/v2/utils/Icon.vue';

export default {
  name: 'TournamentsTable',
  components: {
    Icon,
    Table,
    TableRow,
    TableData,
    Pagination,
    TournamentMobileCard,
    CellTournament,
    CellSimple,
    TournamentStatus,
    TournamentConditions,
  },
  props: {
    tournamentsIds: {
      type: Array,
      default: () => [],
    },
    showPagination: {
      type: Boolean,
      default: false,
    },
    paginationParams: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      loadingState: {
        tournaments: false,
      },
      tableHeaders: [
        this.$t('tournaments.single'),
        this.$t('global.start'),
        this.$t('global.durationShort'),
        this.$t('tournaments.system'),
        this.$t('participants.few'),
        this.$t('global.conditions'),
        this.$t('tournaments.prizePool'),
        this.$t('global.status'),
      ],
    };
  },

  computed: {
    ...mapGetters('tournaments', ['getTournamentTable']),

    tournaments() {
      return this.tournamentsIds?.map(this.getTournamentTable);
    },
  },

  methods: {
    setPage({ page, perPage }) {
      this.$emit('setPage', { page, perPage });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@assets/scss/common/brakepoints';

.tournaments-table {
  width: 100%;
  max-width: 1200px;
}

li {
  list-style: none;
}
.main__table {
  display: none;

  @include min-tablet() {
    display: block;
  }
}
.main__tournaments-card {
  @include min-tablet() {
    display: none;
  }
}
.main__tab-list,
.main__table {
  margin-bottom: 16px;
}
.ratings__title {
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  margin-bottom: 20px;

  @include min-tablet() {
    font-size: 26px;
    line-height: 30px;
  }
}
.ratings-table__title {
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  margin-bottom: 12px;

  @include min-tablet() {
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 16px;
  }
}
.ratings-table__title > a {
  color: #f5f5f7;
  text-decoration: none;

  &:hover {
    opacity: 0.7;
  }
}
.main__table,
.main__tournaments-card {
  margin-bottom: 30px;

  @include min-tablet() {
    margin-bottom: 60px;
  }
}
.empty {
  background-color: #100f0f;
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 6px;

  &-icon {
    font-size: 30px;
    margin-bottom: 20px;
  }

  &-text {
    text-align: center;
    font-size: 18px;
    line-height: 22px;
  }
}
.cell-title {
  font-size: 12px;
  line-height: 16px;
  color: #979797;
  margin-bottom: 4px;
}
.prize {
  display: flex;
  align-items: center;
  gap: 5px;

  @include min-tablet() {
    justify-content: center;
  }
}
.pagination {
  margin-top: 8px;
}
</style>
